<template>
    <v-dialog v-model="dialog.show"  hide-overlay transition="dialog-bottom-transition" class="emp">
      <template v-if="!dialog.no_activator" v-slot:activator="{ on, attrs }">
        <v-btn class="ma-3 primary ma-0 pa-0" fab v-on="on" v-bind="attrs">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog.show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{dialog.title || "Add Task"}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text :disabled="!valid" @click="save()">Save</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list three-line subheader>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Fields</v-list-item-title>
              <v-list-item-subtitle>Please make sure to fill all required fields</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list three-line subheader>
          <v-list-item>
            <v-select
              v-model="task.task_type"
              :items="task_types"
              item-text="text"
              item-value="value"
              :disabled="dialog.finishing"
              :error-messages="!validate(task.task_type, true )? ['[Task Type] is required ']: []"
              label="Task Type"
              color="primary"
            />
          </v-list-item>
          <component
            :is="mapper[task.task_type]"
            :finishing="dialog.finishing"
            v-bind="task"
            ></component>
        </v-list>
      </v-card>
    </v-dialog>
</template>
<script>
export default {
  methods: {
    save() {
      if (this.valid) {
        this.dialog.show = false;
        console.log('imhere', JSON.parse(JSON.stringify(this.task)))
        this.$emit("add", JSON.parse(JSON.stringify(this.task)));
      }
    },
    clear(){
      if(this.dialog.task){
        console.log('dialog task at clear', this.dialog.task)
        this.$set(this,'task',this.dialog.task)
      }else{
        console.log('clearing')
      this.$set(this.task,'task_type',this.dialog.task?.task_type || 'public_sector')
      this.$set(this.task.pharmacy,'name',this.dialog.task?.pharmacy?.name || '')
      this.$set(this.task.pharmacy,'city',this.dialog.task?.pharmacy?.city || '')
      this.$set(this.task.pharmacy,'region',this.dialog.task?.pharmacy?.region || '')
      this.$set(this.task.pharmacy,'person',this.dialog.task?.pharmacy?.person || '')
      this.$set(this.task.pharmacy,'comment',this.dialog.task?.pharmacy?.comment || '')
      this.$set(this.task.pharmacy,'products',this.dialog.task?.pharmacy?.products || [])
      this.$set(this.task.pharmacy,'valid',this.dialog.task?.pharmacy?.valid || 0)
      this.$set(this.task.private_sector,'name',this.dialog.task?.private_sector?.name || '')
      this.$set(this.task.private_sector,'doctor_name',this.dialog.task?.private_sector?.doctor_name || '')
      this.$set(this.task.private_sector,'city',this.dialog.task?.private_sector?.city || '')
      this.$set(this.task.private_sector,'region',this.dialog.task?.private_sector?.region || '')
      this.$set(this.task.private_sector,'alternative_doctor_name',this.dialog.task?.private_sector?.alternative_doctor_name || '')
      this.$set(this.task.private_sector,'products',this.dialog.task?.private_sector?.products || [])
      this.$set(this.task.private_sector,'comment',this.dialog.task?.private_sector?.comment || '')
      this.$set(this.task.private_sector,'valid',this.dialog.task?.private_sector?.valid || 0)
      this.$set(this.task.public_sector,'classification',this.dialog.task?.public_sector?.classification || 'A')
      this.$set(this.task.public_sector,'name',this.dialog.task?.public_sector?.name || '')
      this.$set(this.task.public_sector,'doctor_name',this.dialog.task?.public_sector?.doctor_name || '')
      this.$set(this.task.public_sector,'city',this.dialog.task?.public_sector?.city || '')
      this.$set(this.task.public_sector,'region',this.dialog.task?.public_sector?.region || '')
      this.$set(this.task.public_sector,'products',this.dialog.task?.public_sector?.products || [])
      this.$set(this.task.public_sector,'alternative_doctor_name',this.dialog.task?.public_sector?.alternative_doctor_name || '')
      this.$set(this.task.public_sector,'speciality',this.dialog.task?.public_sector?.speciality || '')
      this.$set(this.task.public_sector,'comment',this.dialog.task?.public_sector?.comment || '')
      this.$set(this.task.public_sector,'valid',this.dialog.task?.public_sector?.valid || 0)
      }
    },
    validate(value,required=false, length=3){
      return (typeof value == "string" && value?.length >= length) || (!required && value?.length == 0);
    },
  },
  watch: {
    show(val) {
      if (val == true) {
        this.clear();
      }
    },
    dilaog_task(){
      this.clear()
    }
  },
  data() {
    return {
      task_types:[
        {
          text:"Public Sector", 
          value:"public_sector", 
        },
        {
          text:"Private Sector", 
          value:"private_sector", 
        },
        {
          text:"Pharmacy Sector", 
          value:"pharmacy", 
        }
      ],
      products:[],
      mapper:{
        public_sector:"public-sector",
        private_sector:"private-sector",
        pharmacy:"pharmacy-sector",
      },
      task: {
        task_type:'public_sector',
        public_sector:{},
        private_sector:{},
        pharmacy:{},
      }
    };
  },
  props: {
    dialog: {
      type: Object,
      default: ()=>({show:false, title:"Add Task"})
    }
  },
  computed: {
    dilaog_task(){
      return this.dialog.task
    },
    show(){
      return this.dialog.show
    },
    valid() {
      return this.task[this.task.task_type].valid;
    }
  },
  components: {
    PublicSector: () =>
      import(
        /* webpackChunkName: "public_sector" */
        "./task_forms/public_sector.vue"
      ),
    PrivateSector: () =>
      import(
        /* webpackChunkName: "private_sector" */
        "./task_forms/private_sector.vue"
      ),
    PharmacySector: () =>
      import(
        /* webpackChunkName: "pharmacy" */
        "./task_forms/pharmacy.vue"
      ),
  }
};
</script>

<style>
.emp{
     border-radius: 0;
    margin: 0;
    height: 100%;
    position: fixed;
    overflow-y: auto;
    top: 0;
    left: 0;
}
</style>